<template>
  <div>
    <div class="row">
      <b-card class="col-12">
        <div>
          <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
        </div>
        <div class="filtro-valorem d-flex">
          <input-autocomplete
            no-label
            v-model="filtro.estabelecimentoId"
            class="autocomplete-extrato flex-fill mr-2"
            :options="opcoes.estabelecimentos"
          />
          <input-text
            no-label
            :placeholder="$t('EXTRATO_TRANSACAO.FILTRO')"
            class="flex-fill mr-2"
            v-model="filtro.termo"
          />
          <div class="d-flex">
            <input-date
              no-label
              class="flex-fill mr-2"
              v-model="filtro.dataInicial"
            />
            <h6 class="mr-2 mt-3">a</h6>
            <input-date no-label class="flex-fill" v-model="filtro.dataFinal" />
            <div class="extrato-transacao d-flex">
              <b-button
                class="ml-2"
                variant="outline-secondary"
                @click="limpar"
              >
                <i class="fas fa-filter"></i>
                <i class="fas fa-times sub-i"></i>
              </b-button>
              <b-button
                class="ml-2"
                variant="outline-valorem-ciano"
                @click="filtrar"
              >
                <div class="d-flex align-items-center">
                  <i class="fas fa-search mr-2"></i>
                  <span>Filtrar</span>
                </div>
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <b-button
            pill
            v-for="(item, i) in opcoes.situacao"
            :key="`btn-visualizar--${i}`"
            :id="`btn-visualizar-${item.value}`"
            :variant="`${filtro.situacao == item.value ? '' : 'outline-'}${
              item.variante
            }`"
            @click="filtrarSituacao(item.value)"
            class="my-2 mr-2"
          >
            <div class="d-flex align-items-center">
              <i
                :class="`far fa-${
                  filtro.situacao == item.value ? 'check-' : ''
                }circle mr-2`"
              ></i>
              <small>{{ item.text }}</small>
            </div>
          </b-button>
        </div>

        <div class="flex-responsive mt-3">
          <div class="d-flex flex-column mr-5">
            <small class="font-weight-bold text-valorem-grafite">{{
              $t("EXTRATO_TRANSACAO.PENDENTES")
            }}</small>
            <h5 class="text-valorem-azul-naval">
              {{ dashboard.valorPendente }}
            </h5>
          </div>
          <div class="d-flex flex-column mr-5">
            <small class="font-weight-bold text-valorem-grafite">{{
              $t("EXTRATO_TRANSACAO.APROVADAS")
            }}</small>
            <h5 class="text-valorem-verde">{{ dashboard.valorAprovado }}</h5>
          </div>
          <div class="d-flex flex-column mr-5">
            <small class="font-weight-bold text-valorem-grafite">{{
              $t("EXTRATO_TRANSACAO.NEGADAS")
            }}</small>
            <h5 class="text-valorem-vermelho">{{ dashboard.valorNegado }}</h5>
          </div>
          <div class="d-flex flex-column mr-5">
            <small class="font-weight-bold text-valorem-grafite">{{
              $t("EXTRATO_TRANSACAO.PENDENTES_CANCELAMENTO")
            }}</small>
            <h5 class="text-valorem-dourado">
              {{ dashboard.valorPendenteCancelamento }}
            </h5>
          </div>
        </div>
        <div class="d-flex align-items-center mb-2">
          <b-button
            @click="visualizar(null)"
            v-if="dadosSelecionados.length == 1"
            class="mr-2"
            variant="outline-primary"
          >
            {{ $t("EXTRATO_TRANSACAO.VISUALIZAR") }}
          </b-button>
          <b-button
            @click="aprovarRejeitar(false)"
            v-if="dadosSelecionados.length && telaExtratoPendentes"
            class="mr-2"
            variant="outline-primary"
          >
            {{ $t("EXTRATO_TRANSACAO.REJEITAR") }}
          </b-button>
          <b-button
            @click="aprovarRejeitar(true)"
            v-if="dadosSelecionados.length && telaExtratoPendentes"
            variant="outline-primary"
          >
            {{ $t("EXTRATO_TRANSACAO.APROVAR") }}
          </b-button>
          <b-button
            id="btn-retentar-pagamento"
            @click="pagamentoManual()"
            variant="valorem-ciano"
            class="mr-3"
            v-if="podeRetentar"
          >
            <div class="d-flex align-items-center">
              Retentativa de pagamento manual
            </div>
          </b-button>
          <b-button
            id="btn-forcar-consulta-extrato"
            @click="forcarConsultaStatus(null)"
            v-if="ehAdmin && podeSolicitarForcarConsultaStatus"
            :disabled="!podeSolicitarForcarConsultaStatus"
            variant="primary"
          >
            {{ $t("LINK_PAGAMENTO.CONSULTA_STATUS_ESITEF") }}
          </b-button>
          <b-button
            id="btn-consulta-agendamento"
            @click="consultaAgendamento(null)"
            v-if="
              ehAdmin &&
              dadosSelecionados.length == 1 &&
              dadosSelecionados[0].situacao == 'Pendente'
            "
            variant="warning"
          >
            {{ $t("LINK_PAGAMENTO.CONSULTA_AGENDAMENTO") }}
          </b-button>
        </div>
        <valorem-tabela
          sem-selecionar-todos
          v-model="tabela.dados"
          :colunas="tabela.colunas"
          :quantidade-itens="tabela.quantidadeItens"
          ref="tabela"
          backend-paginado
          @paginar="listar"
          class="tabela-extrato"
        >
          <template #cell(situacao)="dados">
            <span
              :class="`text-${retornarVariante(
                dados.value
              )} font-weight-bold text-capitalize`"
            >
              {{ dados.value }}
            </span>
          </template>
        </valorem-tabela>
      </b-card>
    </div>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { InputText, InputAutocomplete, InputDate } from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import moment from "moment";
import ExtratoTransacaoService from "@/common/services/extrato_transacao/extrato_transacao.service";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
import AssinaturaCobrancasService from "@/common/services/assinatura_cobrancas/assinatura_cobrancas.service";
import LinkPagamentoService from "@/common/services/link_pagamento/link_pagamento.service";

export default {
  components: {
    InputText,
    InputAutocomplete,
    InputDate,
  },
  data: function () {
    return {
      filtro: {
        estabelecimentoId: "",
        termo: "",
        dataInicial: "",
        dataFinal: "",
        situacao: "",
      },
      opcoes: {
        estabelecimentos: [],
        situacao: [
          {
            value: "Pendente",
            text: "Pendente",
            variante: "valorem-azul-naval",
          },
          { value: "Aprovado", text: "Aprovado", variante: "valorem-verde" },
          { value: "Negado", text: "Negado", variante: "valorem-vermelho" },
          {
            value: "PendenteCancelamento",
            text: "Pendente de cancelamento",
            variante: "valorem-bronze",
          },
        ],
      },
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "", stickyColumn: true },
          {
            key: "codigo",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.CODIGO_LINK"),
          },
          {
            key: "codigoAssinatura",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.CODIGO_ASSINATURA"),
          },
          {
            key: "descricaoLinkPlano",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.DESCRICAO"),
          },
          {
            key: "tipoLinkPagamento",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.TIPO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v)?.text;
            },
          },
          {
            key: "parcela",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.PARCELA"),
          },
          {
            key: "valorTotal",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.VALOR_TOTAL"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "dataCobranca",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.DATA_COBRANCA"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            key: "situacao",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.SITUACAO"),
            formatter: (v) => {
              if (v) return helpers.getEnum(v)?.text || v;
            },
          },
          {
            key: "estabelecimentoNome",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.ESTABELECIMENTO"),
          },
          {
            key: "clienteNome",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.NOME_CLIENTE"),
          },
          {
            key: "numeroCartao",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.NUMERO_CARTAO"),
          },
          { key: "nsu", label: this.$t("EXTRATO_TRANSACAO.TABELA.NSU") },
          {
            key: "codigoAutorizacao",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.COD_AUTORIZACAO"),
          },
          {
            key: "mensagem",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.MENSAGEM"),
          },
          {
            key: "usuarioNome",
            label: this.$t("EXTRATO_TRANSACAO.TABELA.USUARIO"),
          },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
      },
      dashboard: {
        valorAprovado: 0,
        valorNegado: 0,
        valorPendenteCancelamento: 0,
        valorPendente: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("EXTRATO_TRANSACAO.TITULO_LISTA") },
    ]);

    this.filtro.estabelecimentoId =
      this.$store.getters["estabelecimentoSelecionado"];

    let dataAtual = moment();
    this.filtro.dataInicial = dataAtual.format("YYYY-MM-DD");
    //this.filtro.dataFinal = dataAtual.endOf('month').format("YYYY-MM-DD");

    this.buscarEstabelecimentos();
    this.listar();
  },
  methods: {
    pagamentoManual() {
      this.confirmar(
        "Retentativa de pagamento manual",
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        AssinaturaCobrancasService.retentarPagamento(
          this.dadosSelecionados[0].origemId
        )
          .then(() => {
            this.alertaSucesso("Pagamento efetuado com sucesso");
            this.filtrar();
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    filtrarSituacao: function (situacao) {
      if (this.filtro.situacao == situacao) {
        this.filtro.situacao = null;
        this.filtrar();
        return;
      }

      this.filtro.situacao = situacao;
      this.filtrar();
    },
    filtrar: function () {
      this.$refs.tabela.limpar();
      this.listar();
    },
    limpar: function () {
      this.filtro = {
        estabelecimentoId: "",
        termo: "",
        dataInicial: "",
        dataFinal: "",
        situacao: null,
      };
      this.filtrar();
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      ExtratoTransacaoService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;

          this.dashboard = {
            valorAprovado: helpers.formatarValor(res.data.data.valorAprovado),
            valorNegado: helpers.formatarValor(res.data.data.valorNegado),
            valorPendenteCancelamento: helpers.formatarValor(
              res.data.data.valorPendenteCancelamento
            ),
            valorPendente: helpers.formatarValor(res.data.data.valorPendente),
          };
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarEstabelecimentos: function () {
      this.opcoes.estabelecimentos = [
        { value: null, text: "Todos estabelecimentos" },
      ];
      MinhaContaService.buscarEstabelecimento().then((res) => {
        this.opcoes.estabelecimentos.push(
          ...res.data.data.estabelecimentos.map((el) => {
            return {
              value: el.id,
              text: el.nome,
            };
          })
        );
      });
    },
    visualizar: function () {
      let selecionado = this.dadosSelecionados[0];

      this.$router.push({
        name: `extrato-transacao-detalhes-${
          selecionado.ehRecorrenciaNaoPago
            ? "avistaparcelado"
            : selecionado.tipoLinkPagamento.toLowerCase()
        }`,
        params: { id: this.dadosSelecionados[0].origemId },
      });
    },
    retornarVariante: function (situacao) {
      switch (situacao) {
        case "Aprovado":
          return "valorem-verde";
        case "Pendente":
          return "valorem-azul-naval";
        case "Negada":
          return "valorem-vermelho";
        case "Pendente cancelamento":
          return "valorem-bronze";
        case "Estornado":
          return "valorem-cinza-escuro";
      }
    },
    forcarConsultaStatus: function () {
      let idSelecionado = this.dadosSelecionados[0].origemId;
      let tipoLinkSelecionado = this.dadosSelecionados[0].tipoLinkPagamento;

      if (tipoLinkSelecionado == "AVistaParcelado") {
        this.confirmar(
          this.$t("LINK_PAGAMENTO.TITULO_CONSULTA_STATUS_ESITEF"),
          this.$t(`GERAL.MENSAGEM_CONFIRMAR_ACAO`),
          this.$t("LINK_PAGAMENTO.CONSULTA_STATUS_ESITEF"),
          this.$t(`GERAL.CANCELAR`)
        ).then((confirmado) => {
          if (!confirmado) return;

          this.$store.dispatch(START_LOADING);
          ExtratoTransacaoService.forcarAtualizacaoStatusCobrancaUnica(
            idSelecionado
          )
            .then(() => {
              this.toastSucesso("Consulta de status efetuada com sucesso");
              this.listar(this.paginaAtual, this.porPagina);
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        });
      } else {
        this.confirmar(
          this.$t("ASSINATURAS.TITULO_CONSULTA_STATUS_ESITEF"),
          this.$t(`GERAL.MENSAGEM_CONFIRMAR_ACAO`),
          this.$t("ASSINATURAS.CONSULTA_STATUS_ESITEF"),
          this.$t(`GERAL.CANCELAR`)
        ).then((confirmado) => {
          if (!confirmado) return;

          this.$store.dispatch(START_LOADING);
          ExtratoTransacaoService.forcarAtualizacaoStatusCobrancaParcelada(
            idSelecionado
          )
            .then(() => {
              this.toastSucesso("Consulta de status efetuada com sucesso");
              this.listar(this.paginaAtual, this.porPagina);
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        });
      }
    },
    consultaAgendamento: function () {
      let idSelecionado = this.dadosSelecionados[0].linkPagamentoId;

      this.confirmar(
        this.$t("LINK_PAGAMENTO.TITULO_CONSULTA_AGENDAMENTO"),
        this.$t(`GERAL.MENSAGEM_CONFIRMAR_ACAO`),
        this.$t("LINK_PAGAMENTO.CONSULTA_AGENDAMENTO"),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;

        this.$store.dispatch(START_LOADING);
        LinkPagamentoService.forcarObterConsultaAgendamento(idSelecionado)
          .then(() => {
            this.toastSucesso("Consulta de agendamentos efetuada com sucesso");
            this.listar(this.paginaAtual, this.porPagina);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
  computed: {
    telaExtratoPendentes: function () {
      return this.$route.name == "extrato-transacao-pendentes";
    },
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    estabelecimentoSelecionado: function () {
      return this.$store.getters["estabelecimentoSelecionado"];
    },
    podeRetentar: function () {
      if (this.dadosSelecionados.length != 1) return false;

      var negado =
        this.dadosSelecionados[0].situacao == "Negado" &&
        this.dadosSelecionados[0].tipoLinkPagamento == "Recorrencia";

      var dtHr = this.dadosSelecionados[0].dataCobranca.split("T");
      var dt = dtHr[0].split("-");
      var data = new Date(dt[0], dt[1] - 1, dt[2]);
      var pendenteAtrasadoEhAdmin =
        this.dadosSelecionados[0].situacao == "Pendente" && data < new Date();
      this.dadosSelecionados[0].tipoLinkPagamento == "Recorrencia" &&
        this.ehAdmin;

      return negado || pendenteAtrasadoEhAdmin;
    },
    podeMostrarBotaoForcarConsultaStatus: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return true;
    },
    podeSolicitarForcarConsultaStatus: function () {
      if (this.dadosSelecionados.length != 1) return false;

      return (
        this.dadosSelecionados.length == 1 &&
        this.dadosSelecionados[0].podeForcarAtualizacaoStatus
      );
    },
    ehAdmin: function () {
      return this.$store.getters.ehAdmin;
    },
  },
  watch: {
    estabelecimentoSelecionado: function () {
      this.listar();
    },
  },
};
</script>

<style lang="scss">
.autocomplete-extrato .dropdown-content {
  margin-top: 0px !important;
}
.extrato-transacao .btn {
  height: 35px;
}
.tabela-extrato td {
  font-size: 14px;
  padding: 4px !important;
  white-space: nowrap;
}
.tabela-extrato th {
  white-space: nowrap;
  font-size: 14px;
}
</style>
